import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Input,
  Box,
  Text,
  SimpleGrid,
  Image,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { FaUpload, FaTrashAlt } from "react-icons/fa";
import DatePicker from "../DateTimePicker";
import axios from "axios";
import constants from "../../util/constant";
import { useAuth } from "../../hooks/AuthProvider";

const ImageUploadModal = ({ isOpen, onClose, patientId, onRefresh }) => {
  const [images, setImages] = useState({
    front: { file: null, imageId: null, fileName: null },
    left: { file: null, imageId: null, fileName: null },
    right: { file: null, imageId: null, fileName: null },
    upper: { file: null, imageId: null, fileName: null },
    lower: { file: null, imageId: null, fileName: null },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [date, setDate] = useState("");
  const [note, setNote] = useState("");
  const auth = useAuth();
  const toast = useToast();

  useEffect(() => {
    if (!date) {
      const now = new Date();
      const defaultDate = now.toISOString().slice(0, 16);
      setDate(defaultDate);
    }
  }, [date]);

  const handleImageChange = (view, file) => {
    setImages((prev) => ({
      ...prev,
      [view]: { ...prev[view], file },
    }));
  };

  const handleImageDelete = (view) => {
    setImages((prev) => ({
      ...prev,
      [view]: { file: null, imageId: null, fileName: null },
    }));
  };

  const callDetectionAPI = async (fileName, imageId) => {
    console.log(`fileName: ${fileName}`)
    console.log(`imageId: ${imageId}`)

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://flaskapi.dent.lingosoft.co/test_detect?img=${fileName}&image_id=${imageId}&socket_id=null`,
      headers: { }
    };
    
    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.error('Detection API error:', error);
    });
};

  const uploadImageToDiagnosis = async (file, viewType, diagnosisId) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("view_type", viewType);
    formData.append("diagnosis_id", diagnosisId);

    try {
      const response = await axios.post(`${constants.host}/upload_img`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Call detection API after successful upload
      await callDetectionAPI(response.data.file, response.data.image_id);

      return response.data;
    } catch (error) {
      console.error(`Failed to upload ${viewType} image:`, error);
      throw error;
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      // 1. First, create the diagnosis record
      const submissionData = {
        patients_id: patientId,
        number_image: "five",
        diagnosis_status: "waiting",
        note,
        inspector_id: auth.user.uid,
        created_datetime: date, // Include the selected date
      };

      //console.log(`user: ${JSON.stringify(auth.user)}`)
      console.log(submissionData)

      const diagnosisResponse = await axios.post(
        `${constants.mobile_backend_host}/diagnosis/insert_diagnosis`,
        submissionData
      );

      const diagnosisId = diagnosisResponse.data.data.diagnosis_id;

      // 2. Then upload each image with the diagnosis_id
      const imageUploads = Object.entries(images)
        .filter(([_, img]) => img.file !== null)
        .map(async ([view, img]) => {
          try {
            await uploadImageToDiagnosis(img.file, view, diagnosisId);
          } catch (error) {
            console.error(`Failed to upload ${view} image:`, error);
            throw error;
          }
        });

      await Promise.all(imageUploads);

      toast({
        title: "Upload successful",
        description: "All images have been uploaded successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      onRefresh();
      onClose();
    } catch (error) {
      console.error("Upload failed:", error);
      toast({
        title: "Upload failed",
        description: "There was an error uploading the images",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const isAnyImageSelected = Object.values(images).some((img) => img.file !== null);

  return (
    <Modal size={"3xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>อัพโหลดภาพ</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={6}>
            <Text mb={2} fontWeight="bold">
              เลือกวันที่ถ่ายภาพ
            </Text>
            <DatePicker
              initialDate={date}
              onDateChange={(newDate) => setDate(newDate)}
            />
          </Box>
          <Box mb={6}>
            <Text mb={2} fontWeight="bold">
              หมายเหตุ
            </Text>
            <Input
              placeholder="ระบุหมายเหตุ (ถ้ามี)"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </Box>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
            {[
              { id: "front", label: "ภาพถ่ายฟันหน้า" },
              { id: "left", label: "ภาพถ่ายฟันด้านซ้าย" },
              { id: "right", label: "ภาพถ่ายฟันด้านขวา" },
              { id: "upper", label: "ภาพถ่ายฟันด้านบน" },
              { id: "lower", label: "ภาพถ่ายฟันด้านล่าง" },
            ].map(({ id, label }) => (
              <Box key={id} width="100%">
                <Text mb={2} fontWeight="bold">
                  {label}
                </Text>
                {images[id].file ? (
                  <Box position="relative">
                    <Image
                      src={URL.createObjectURL(images[id].file)}
                      alt={label}
                      borderRadius="8px"
                      objectFit="cover"
                      width="100%"
                      height="200px"
                    />
                    <IconButton
                      aria-label="Delete image"
                      icon={<FaTrashAlt />}
                      colorScheme="red"
                      size="sm"
                      position="absolute"
                      top="5px"
                      right="5px"
                      onClick={() => handleImageDelete(id)}
                    />
                  </Box>
                ) : (
                  <Button
                    leftIcon={<FaUpload />}
                    colorScheme="teal"
                    variant="outline"
                    as="label"
                    cursor="pointer"
                    width="100%"
                  >
                    เลือกไฟล์
                    <Input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleImageChange(id, e.target.files[0])}
                      hidden
                    />
                  </Button>
                )}
              </Box>
            ))}
          </SimpleGrid>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleSubmit}
            isDisabled={!date || !isAnyImageSelected || isSubmitting}
            isLoading={isSubmitting}
          >
            อัพโหลด
          </Button>
          <Button onClick={onClose} isDisabled={isSubmitting}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ImageUploadModal;