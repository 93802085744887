import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Image,
  Input,
  Text,
  VStack,
  Spinner,
  Alert,
  AlertIcon,
  HStack,
  Flex,
} from "@chakra-ui/react";
import axios from "axios";

const ImageUploadComponent = ({ apiUrl, title }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [outputImage, setOutputImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Reference for the file input element
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setOutputImage(null); // Clear previous output image
    setError(null); // Clear previous errors
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setError("Please select an image to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("image", selectedFile);

    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(apiUrl, formData, {
        responseType: "blob", // Expect a binary response (image file)
      });

      const outputImageUrl = URL.createObjectURL(response.data);
      setOutputImage(outputImageUrl);
    } catch (err) {
      setError(
        err.response?.data?.error || "An error occurred while uploading the image."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleClear = () => {
    setSelectedFile(null);
    setOutputImage(null);
    setError(null);
    setIsLoading(false);

    // Clear the file input field
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const originalImageUrl = selectedFile ? URL.createObjectURL(selectedFile) : null;

  return (
    <VStack spacing={6} align="center" w="full" py={8} px={4}>
      {/* Header Section */}
      <Box textAlign="center">
        <Text fontSize="2xl" fontWeight="bold" mb={2} color="teal.600">
          {title}
        </Text>
        <Text fontSize="md" color="gray.500">
          Upload an image and let the system detect.
        </Text>
      </Box>

      {/* File Input */}
      <Input
        ref={fileInputRef} // Attach reference to the input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        disabled={isLoading}
        variant="filled"
        focusBorderColor="teal.400"
      />

      {/* Error Alert */}
      {error && (
        <Alert status="error" borderRadius="md">
          <AlertIcon />
          {error}
        </Alert>
      )}

      {/* Buttons (on the same line) */}
      <HStack spacing={4}>
        <Button
          colorScheme="teal"
          onClick={handleUpload}
          isLoading={isLoading}
          loadingText="Processing..."
          isDisabled={!selectedFile || isLoading}
          size="lg"
          shadow="md"
        >
          Upload and Detect
        </Button>
        <Button
          colorScheme="orange"
          onClick={handleClear}
          isDisabled={isLoading}
          size="lg"
          shadow="md"
        >
          Clear
        </Button>
      </HStack>

      {/* Loading Spinner */}
      {isLoading && <Spinner size="xl" thickness="4px" color="teal.500" />}

      {/* Original and Output Image */}
      {originalImageUrl && outputImage && (
        <Flex mt={6} justify="center" align="center" gap={6}>
          <Box textAlign="center">
            <Text fontSize="lg" fontWeight="bold" color="teal.600" mb={4}>
              Original Image
            </Text>
            <Image
              src={originalImageUrl}
              alt="Original Image"
              boxSize="400px"
              objectFit="contain"
              borderRadius="lg"
              border="2px solid"
              borderColor="teal.300"
              shadow="lg"
            />
          </Box>
          <Box textAlign="center">
            <Text fontSize="lg" fontWeight="bold" color="teal.600" mb={4}>
              Detection Result
            </Text>
            <Image
              src={outputImage}
              alt="Output Image"
              boxSize="400px"
              objectFit="contain"
              borderRadius="lg"
              border="2px solid"
              borderColor="teal.300"
              shadow="lg"
            />
          </Box>
        </Flex>
      )}
    </VStack>
  );
};

export default ImageUploadComponent;
