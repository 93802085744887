import React, { useState, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import { Box, Button, Container, Flex, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import axios from "axios";
import Navbar from "../components/Navbar";
import Header from "../components/Header";

const MouthDetection = () => {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [isDetecting, setIsDetecting] = useState(false);
  const toast = useToast();
  const [mouthRatio, setMouthRatio] = useState(0);
  const [mouthClass, setMouthClass] = useState("");
  const videoConstraints = {
    width: 640,
    height: 480,
    facingMode: "user",
  };

  const captureAndDetect = async () => {
    if (!webcamRef.current) return;

    // Capture image from webcam
    const imageSrc = webcamRef.current.getScreenshot();

    try {
      // Send the captured image to the detect API
      const formData = new FormData();
      formData.append("image", dataURItoBlob(imageSrc), "webcam.jpg");

      const response = await axios.post(`${process.env.REACT_APP_MOUTH_API}/detect/mouth`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const detections = response.data.detections;

      // Draw bounding boxes on the canvas
      drawDetections(detections);
    } catch (error) {
        console.log("Detection failed")
    }
  };

  // Convert data URI to Blob
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const calculateMouthRatio = (box, imageWidth, imageHeight) => {
    // Destructure the bounding box coordinates
    const [x1, y1, x2, y2] = box;
  
    // Calculate the width and height of the bounding box
    const boxWidth = x2 - x1;
    const boxHeight = y2 - y1;
  
    // Calculate the area of the bounding box
    const boxArea = boxWidth * boxHeight;
  
    // Calculate the area of the entire image
    const imageArea = imageWidth * imageHeight;
  
    // Calculate and return the ratio
    return boxArea / imageArea;
  };
  
  const drawDetections = (detections) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
  
    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  
    // Draw each detection
    detections.forEach((detection) => {
      const { box, class_name, confidence } = detection;
      const [x1, y1, x2, y2] = box;
  
      // Calculate the ratio for the mouth box
      const ratio = calculateMouthRatio(box, canvas.width, canvas.height);
      console.log(`Mouth box ratio: ${ratio.toFixed(4)}`);
  
      // Draw bounding box
      ctx.strokeStyle = "green";
      ctx.lineWidth = 2;
      ctx.strokeRect(x1, y1, x2 - x1, y2 - y1);
  
      // Draw label
      ctx.fillStyle = "green";
      ctx.font = "16px Arial";
      const label = `${class_name} (${(confidence * 100).toFixed(1)}%)`;
      console.log(`Label: ${label}`);
      //ctx.fillText(label, x1, y1 - 5);
  
      // Draw ratio
      const ratioFloat = (ratio * 100).toFixed(2);
      setMouthRatio(ratioFloat)
      setMouthClass(class_name)
      const ratioText = `Ratio: ${ratioFloat}%`;
      console.log(`Ratio: ${ratioText}`);
      //ctx.fillText(ratioText, x1, y2 + 20);
    });

    if(detections.length <= 0){
      setMouthRatio(0);
      setMouthClass("")
    }
  };  

  useEffect(() => {
    let interval;
    if (isDetecting) {
      interval = setInterval(captureAndDetect, 1000);
    } else {
      clearInterval(interval);
      setMouthRatio(0);
      setMouthClass("")
    }

    return () => clearInterval(interval);
  }, [isDetecting]);

  return (

    <>
			<Header />
			<Navbar />
			<Container
				bgColor={useColorModeValue("gray.200", "blue.900")}
				maxW="full"
				mt={0}
				centerContent
				overflow="hidden"
				py={10}
			>
        <Flex direction="column" align="center" p={4}>
              <Text fontSize="xl" fontWeight="bold" mb={4}>
                Mouth Detection
              </Text>

              <Box position="relative" width={videoConstraints.width} height={videoConstraints.height} mb={4}>
                {/* Webcam Feed */}
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                  }}
                />
                {/* Canvas for Bounding Boxes */}
                {isDetecting &&<canvas
                  ref={canvasRef}
                  width={videoConstraints.width}
                  height={videoConstraints.height}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                />}
              </Box>
              <Flex justify="center" mb={4}>
                {mouthRatio <= 5 && mouthClass == "close" && <Text fontSize="lg" fontWeight="bold" mr={4}>
                  กรุณาขยับเข้าใกล้กล้อง  
                </Text>}
                {mouthRatio <= 8 && mouthClass == "open" && <Text fontSize="lg" fontWeight="bold" mr={4}>
                  กรุณาขยับเข้าใกล้กล้อง  
                </Text>}
              </Flex>
              <Button
                colorScheme={isDetecting ? "red" : "teal"}
                onClick={() => setIsDetecting((prev) => !prev)}
                mb={4}
              >
                {isDetecting ? "Stop Detection" : "Start Detection"}
              </Button>
              
        </Flex>
      </Container>
      </>
    
  );
};

export default MouthDetection;
