import React, { useState, useEffect } from "react";
import { Box, Flex, Select, Text } from "@chakra-ui/react";
const moment = require('moment-timezone');

const DatePicker = ({ initialDate, onDateChange, isDisable = false }) => {
  const currentYear = new Date().getFullYear();
  const buddhistCurrentYear = currentYear + 543 + 1; // Convert to Buddhist year
  const years = Array.from({ length: 100 }, (_, i) => buddhistCurrentYear - i);
  const months = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];

  const [selectedDay, setSelectedDay] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedHour, setSelectedHour] = useState("");
  const [selectedMinute, setSelectedMinute] = useState("");
  const [days, setDays] = useState([]);

  useEffect(() => {
    //const now = moment();
    const now = moment.tz("Asia/Bangkok");
    const dateToUse = initialDate ? moment(initialDate) : now;

    const [year, month, day, hour, minute] = dateToUse
      .format("YYYY-MM-DD-HH-mm")
      .split(/[-:]/)
      .map(Number);

    setSelectedYear(String(year + 543)); // Convert to Buddhist year
    setSelectedMonth(String(month));
    setSelectedDay(String(day));
    setSelectedHour(String(hour));
    setSelectedMinute(String(minute));
  }, [initialDate]);

  useEffect(() => {
    if (selectedYear && selectedMonth) {
      const daysInMonth = new Date(selectedYear - 543, selectedMonth, 0).getDate(); // Convert to Gregorian year
      setDays(Array.from({ length: daysInMonth }, (_, i) => String(i + 1)));
    } else {
      setDays([]);
    }
  }, [selectedYear, selectedMonth]);

  const handleDayChange = (event) => {
    const newDay = event.target.value;
    setSelectedDay(newDay);
    updateDateChange(newDay, selectedMonth, selectedYear, selectedHour, selectedMinute);
  };

  const handleMonthChange = (event) => {
    const newMonth = event.target.value;
    setSelectedMonth(newMonth);
    setSelectedDay(""); // Reset day to ensure valid selections
  };

  const handleYearChange = (event) => {
    const newYear = event.target.value;
    setSelectedYear(newYear);
    setSelectedDay(""); // Reset day to ensure valid selections
  };

  const handleHourChange = (event) => {
    const newHour = event.target.value;
    setSelectedHour(newHour);
    updateDateChange(selectedDay, selectedMonth, selectedYear, newHour, selectedMinute);
  };

  const handleMinuteChange = (event) => {
    const newMinute = event.target.value;
    setSelectedMinute(newMinute);
    updateDateChange(selectedDay, selectedMonth, selectedYear, selectedHour, newMinute);
  };

  const updateDateChange = (day, month, year, hour, minute) => {
    if (day && month && year && hour && minute) {
      onDateChange(formatDate(day, month, year - 543, hour, minute)); // Convert back to Gregorian year
    }
  };

  const formatDate = (day, month, year, hour, minute) => {
    return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
      2,
      "0"
    )}T${String(hour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`;
  };

  return (
    <Flex
      flexWrap="wrap"
      flexDirection={{ base: "column", md: "row" }}
      gap={{ base: 4, md: 4 }}
      width="100%"
    >
      {/* Date Section */}
      <Box
        border="1px solid #ccc"
        borderRadius="8px"
        p={{ base: 4, md: 6 }}
        width={{ base: "100%", md: "57%" }}
        position="relative"
      >
        <Text
          as="span"
          position="absolute"
          top="-10px"
          left="15px"
          bg="white"
          px="5px"
          fontSize={{ base: "sm", md: "md" }}
          fontWeight="bold"
        >
          เลือกวันที่
        </Text>
        <Box display="flex" flexWrap="wrap" gap={4}>
          {/* Day Dropdown */}
          <Select
            flex="1"
            isDisabled={isDisable || !selectedMonth || !selectedYear}
            placeholder="วัน"
            value={selectedDay || ""}
            onChange={handleDayChange}
            borderRadius="8px"
          >
            {days.map((day) => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </Select>

          {/* Month Dropdown */}
          <Select
            flex="2"
            isDisabled={isDisable}
            placeholder="เดือน"
            value={selectedMonth || ""}
            onChange={handleMonthChange}
            borderRadius="8px"
          >
            {months.map((month, index) => (
              <option key={index + 1} value={String(index + 1)}>
                {month}
              </option>
            ))}
          </Select>

          {/* Year Dropdown */}
          <Select
            flex="1"
            isDisabled={isDisable}
            placeholder="ปี"
            value={selectedYear || ""}
            onChange={handleYearChange}
            borderRadius="8px"
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Select>
        </Box>
      </Box>

      {/* Time Section */}
      <Box
        border="1px solid #ccc"
        borderRadius="8px"
        p={{ base: 4, md: 6 }}
        width={{ base: "100%", md: "40%" }}
        position="relative"
      >
        <Text
          as="span"
          position="absolute"
          top="-10px"
          left="15px"
          bg="white"
          px="5px"
          fontSize={{ base: "sm", md: "md" }}
          fontWeight="bold"
        >
          เลือกเวลา
        </Text>
        <Box display="flex" flexWrap="wrap" gap={4}>
          {/* Hour Dropdown */}
          <Select
            flex="1"
            isDisabled={isDisable}
            placeholder="ชั่วโมง"
            value={selectedHour || ""}
            onChange={handleHourChange}
            borderRadius="8px"
          >
            {Array.from({ length: 24 }, (_, i) => (
              <option key={i} value={String(i)}>
                {String(i).padStart(2, "0")}
              </option>
            ))}
          </Select>

          {/* Minute Dropdown */}
          <Select
            flex="1"
            isDisabled={isDisable}
            placeholder="นาที"
            value={selectedMinute || ""}
            onChange={handleMinuteChange}
            borderRadius="8px"
          >
            {Array.from({ length: 60 }, (_, i) => (
              <option key={i} value={String(i)}>
                {String(i).padStart(2, "0")}
              </option>
            ))}
          </Select>
        </Box>
      </Box>
    </Flex>
  );
};

export default DatePicker;
